<template>
  <section class="full-height">
    
    
    
    <div v-if="showPathSearchBar" class="columns is-centered is-gapless" style="padding-top: 15px">
      <div class="column is-1 is-offset-1" style="line-height: 40px;text-align: right;margin-right:5px">S3 Path</div>
      <b-input v-model="S3Path" type="is-success" class="column is-7"></b-input>
      <b-button class="column is-1 button is-primary" style="margin-left: 5px" @click="GetPathData()">
        <strong>Load</strong>
      </b-button>
      <b-button class="column is-1 button is-primary" style="margin-left: 5px" @click="MakePublic()">
        <strong>Make public</strong>
      </b-button>
    </div>
    
    <div class="columns is-centered is-gapless is-multiline" style="margin: 5px" v-show="S3Data.length > 0">
      
    </div>      
    <div class="columns is-centered is-gapless is-multiline" style="margin: 5px" v-show="S3Data.length > 0">      
      <div class="column is-10 is-offset-1 MasterItemSelector">
        <div class="MasterItemWrap" v-for="(masterItem, masterIndex) in S3Data" v-bind:key="masterIndex">
          <img :src="masterItem.thumb" width="100%" @click="editVideo(masterItem, masterIndex)"/>
          <div class="VideoDuration">{{ Math.round(masterItem.duration) }}s</div>
          <!-- <b-button class="button is-primary editVideo" @click="editVideo(masterItem, masterIndex)">View</b-button> -->
        </div>
      </div>
    </div>
    
    <div class="columns is-centered is-multiline is-gapless" v-show="S3Data.length > 0">
      <div>To Add, Select a video</div>
    </div>
    
    <div class="columns is-centered is-multiline is-gapless" v-show="editItem !== null">
      <div class="column is-12">
        <video ref="videoPlayer" class="video-js"></video>
      </div>
      
      <div class="column is-12" v-if="editItemIndex !== null">
        <div class="" v-for="(range, rangeIndex) in finalEdit[editItemIndex].ranges" v-bind:key="rangeIndex">
          <VideoRangeSelector
            :sections="finalEdit[editItemIndex].sections" 
            :range="range" 
            :from="finalEdit[editItemIndex].sections[0]" 
            :till="finalEdit[editItemIndex].sections[finalEdit[editItemIndex].sections.length -1]"
            @fromchanged="setVideoTime"
            @tillchanged="setVideoTime"
          />
        </div>
      </div>
      
      <div class="column is-12">
        <b-button class="button is-primary AddVideo" @click="AddVideoRange()">Add Range</b-button>
        <b-button class="button is-primary RemoveVideo" @click="RemoveVideoRange()">Remove Range</b-button>
      </div>
    </div>
    
    
    
    <div class="columns is-centered is-multiline is-gapless" v-show="this.finalEdit.length > 0">
      <!-- <div class="column is-10 is-offset-1"> OUTPUT: </div> -->
      <b-table
          class="column is-10 is-offset-1"
          :key="timeKey"
          :data="finalEdit"
          :bordered="true"
          :striped="true"
          :narrowed="false"
          :hoverable="true"

          :loading="false"
          :focusable="true"

          :header-checkable="false"
          :mobile-cards="true"
          
          >
          <!-- checkable -->
          <!-- :checked-rows.sync="tbl1_checkedRows" -->
          
          <template>
              <b-table-column field="index" label="Pos" width="50" v-slot="props" centered>  
                {{ props.index }}
              </b-table-column>
              
              <b-table-column field="full" label="Item" width="400" v-slot="props" centered>  
                {{ props.row.full }}
              </b-table-column>
              <b-table-column field="ranges" label="Ranges" width="400" v-slot="props" centered>  
                <div class="" v-for="(range, rangeIndex) in props.row.ranges" v-bind:key="`t_${rangeIndex}`">
                  <div class="RangeTimes">from:{{ getTimeStr(range[0]) }} till:{{ getTimeStr(range[1]) }}</div>
                  <!-- {{ JSON.stringify(props.row.ranges) }} -->
                </div>
              </b-table-column>
              
              <b-table-column field="action" label="action" width="100" v-slot="props" centered>  
                
                <b-button class="button is-primary" @click="editSelectedVideo(props)" style="margin-bottom: 25px;">
                  <strong>Edit</strong>
                </b-button>
                
                <b-button class="button is-primary" @click="deleteSelectedVideo(props)">
                  <strong>Delete</strong>
                </b-button>
              </b-table-column>
              
              
              
          </template>
          


          <template slot="empty">
              <section class="section">
                  <div class="content has-text-grey has-text-centered">
                      <p>
                          <b-icon
                              icon="emoticon-sad"
                              size="is-large">
                          </b-icon>
                      </p>
                      <p>Nog geen videos toegevoegd.</p>
                  </div>
              </section>
          </template>
      </b-table>
    </div>
    
    
    <div class="columns is-centered" v-show="this.finalEdit.length > 0">
      <b-field>
        <b-switch v-model="uploadVimeo">upload to Vimeo</b-switch>
      </b-field>
            
      <b-field>
        <b-switch v-model="uploadAWS">Download Video </b-switch>
      </b-field>
      
      <b-field>
        <b-switch v-model="uploadM3u8">IVS Video </b-switch>
      </b-field>
      
      <b-button class="column is-3 is-primary buttonExtra" @click="CreateVideoUrl">Create Video</b-button>
    </div>
    
    
    
    <div class="columns is-centered is-multiline is-gapless" v-show="this.videoURL2 !== ''">
      <div class="column is-12">
        <video ref="videoPlayer2" class="video-js"></video>
      </div>
    </div>
    
    
    <div class="columns is-centered is-multiline is-gapless" v-show="isFinished === true">
      <div class="column is-12">
        finished....
      </div>
    </div>
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

import videojs from 'video.js';
import { registerIVSTech } from 'amazon-ivs-player';
import 'video.js/dist/video-js.css';

import VideoRangeSelector from "@/components/public/general/video_range_selector.vue";
import { getTimeStr } from '@/helpers/functions';

export default {
  name: "VideoEditor", 
  components: {
    VideoRangeSelector
  },
         
  data() {
    return {
      pageLoading : false,
      pageLoadingTimeout : null,
      timeKey: new Date().getTime(),
      
      player: null, 
      ivsPlayer: null,
      videoURL: '',
      
      player2: null, 
      ivsPlayer2: null,
      videoURL2: '',
      
      videoOptions: {
        autoplay: true,
        controls: true,
        techOrder: ["AmazonIVS"],
        width: "1920"
      },      
      
      // S3Path: "ivs/v1/041126794743/sumyM0eZwz95/",
      S3Path: "ivs/v1/041126794743/fgMa5nYCQ9Pe/",
      showPathSearchBar: false,
      // S3Path: "ivs/v1/041126794743/2xIo7vvooNMT/",
      bucket: "meds-streams",
      S3Data: [],
      rootPath: "",
      
      editItem: null,
      editItemIndex: null,
      
      finalEdit: [],
      jwt: "",
      uploadVimeo: true,
      uploadAWS: false,
      uploadM3u8: false,
      videoName: "Webinar",
      isFinished: false,
    }
  },
  
  created(){
    this.rootPath = process.env.VUE_APP_WEB_ROOT
  },
  
  mounted(){
    // register the tech with videojs    
    if (this.$route.query.path) {
      this.S3Path = this.$route.query.path || ""
    } else {
      this.showPathSearchBar = true
    }
    
    this.jwt = this.$route.query.jwt || ""
    // console.info(`jwt:`, this.jwt)
    
    this.videoName = this.$route.query.videoName || "Webinar"
    // console.info(`videoName:`, this.videoName)
    
    if (this.S3Path.indexOf('ivs/v1/041126794743/') !== 0 || this.S3Path.length <= 'ivs/v1/041126794743/'.length+5) {
      return this.$store.commit("dialog/setDialog", 
          { DialogType: 'alert', 
            title: 'invalid path', 
            message: 'invalid path', 
          }
      );
    }
    
    console.log(`wasmWorker: ${this.createAbsolutePath('/assets/amazon-ivs-wasmworker.min.js')}`)

    registerIVSTech(videojs,  {
      wasmWorker: this.createAbsolutePath('/assets/amazon-ivs-wasmworker.min.js'),
      wasmBinary: this.createAbsolutePath('/assets/amazon-ivs-wasmworker.min.wasm'),
    });
    
    // Init the player 1
    this.player = videojs(this.$refs.videoPlayer, this.videoOptions, () => {
      console.log('Player is ready to use!');
      // play the stream
      // this.startVideo();
      this.ivsPlayer = this.player.getIVSPlayer();
    })
    
    // Init the player2
    this.player2 = videojs(this.$refs.videoPlayer2, this.videoOptions, () => {
      console.log('Player is ready to use!');
      // play the stream
      // this.startVideo();
      this.ivsPlayer2 = this.player.getIVSPlayer();
    })
    
    this.GetPathData();
  },
  
  beforeUnmount() {
    // Destroy the player instance
    if(this.player) {
      this.player.dispose();
    }
    if(this.player2) {
      this.player2.dispose();
    }
  },
  
  methods: {
    startVideo() {
      // console.info(`Staring video: `, this.videoURL)
      if (!this.videoURL) {
        console.info(`no video url`)
        return;
      } 
      
      this.player.src(this.videoURL);
    },
    resetPlayerState () {
      if (this.player) {
        if (this.ivsPlayer) {
          this.ivsPlayer.delete();
        }

        this.ivsPlayer = null;

        this.player.dispose();
        this.player = null;
      }
    },
    hideVideo(){
      this.videoURL = ''      
    },
    startVideo2() {
      // console.info(`Staring video: `, this.videoURL)
      if (!this.videoURL2) {
        console.info(`no video url`)
        return;
      } 
      
      this.player2.src(this.videoURL2);
    },
    hideVideo2(){
      this.videoURL2 = ''      
    },
    setVideoTime(newTime){
      // console.info(`setVideoTime:`, newTime)
      this.ivsPlayer.seekTo(newTime)
      this.timeKey = new Date().getTime()
    },    
    createAbsolutePath(assetPath) {
      console.log( document.URL );
      return new URL(assetPath, document.URL).toString();
    },
    
    async MakePublic() {
      await this.ShowPageLoading()
      await this.$store.dispatch('video_editor/makePathPublic', {
        "bucket" : this.bucket,
        "prefix" : this.S3Path,
        "useCache": true,
        "msjwt": this.jwt
      });
      await this.HidePageLoading()
      
      //try again to fetch the data
      // await this.GetPathData()
      window.location.reload(true);
    },
    async GetPathData() {
      
      await this.ShowPageLoading()
      const S3Data = await this.$store.dispatch('video_editor/getData', {
        "bucket" : this.bucket,
        "prefix" : this.S3Path,
        "useCache": true,
        "resolution": "160p30",
        "msjwt": this.jwt
      });
      
      // console.info(`S3Data:`, S3Data)
      await this.HidePageLoading()
      if (!S3Data || S3Data.masterItems.length <= 0) {
        return this.$store.commit("dialog/setDialog", 
            { DialogType: 'alert', 
              title: 'Geen Videos gevonden', 
              message: 'Geen Videos gevonden', 
            }
        );
      }
      
      
      this.S3Data = S3Data.masterItems
      
      
      // console.info(`S3Data:::`, this.S3Data.length)
      
      
    },
    
    
     
    ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    editVideo(masterItem){
      this.editItem = masterItem
      this.videoURL = masterItem.full
      this.editItemIndex = null;
      this.startVideo()
    },
    
    
    AddVideoRange(){
      if (this.editItemIndex === null || this.editItem.full !== this.finalEdit[this.editItemIndex].full) {
        this.finalEdit.push({
          master: this.editItem,
          full: this.editItem.full,
          sections: this.CalcSections(this.editItem.m3u8),
          ranges: []
        })
        
        this.editItemIndex = this.finalEdit.length -1
        // console.info(`this.finalEdit:`, this.finalEdit)
      }
      
      //Add Range:  
      this.finalEdit[this.editItemIndex].ranges.push(
        [0, parseFloat(this.editItem.duration)]  
      )
      // console.info(`ranges:`, this.finalEdit[this.editItemIndex].ranges)
      
    },
    
    RemoveVideoRange(){
      this.finalEdit[this.editItemIndex].ranges.pop()
      
      if (this.finalEdit[this.editItemIndex].ranges.length <=0) {
        //Remove completely ?
        this.finalEdit.splice(this.editItemIndex, 1);
        this.editItemIndex = null
      }      
    },
    
    CalcSections(m3u8){
      let Rtn = []
      let endTime = 0;
      for(let section of m3u8.videparts) {
        Rtn.push(section.startTime)
        endTime = section.endTime;
      }
      Rtn.push(endTime)
      return Rtn;
    },
    
    // removeVideo(video){
    //   console.info(`RemoveVideo:`, video)
    // },
    deleteSelectedVideo(videoProp){
      // console.info(`deleteSelectedVideo: `, videoProp)
      
      //Remove completely ?
      this.finalEdit.splice(videoProp.index, 1);
      this.editItemIndex = null      
      this.editItem = null
      this.videoURL = ''      
      this.videoURL2 = ''
      this.hideVideo()
    },
    
    async editSelectedVideo(videoProp){
      // console.info(`editSelectedVideo: `, videoProp)   
      this.editItemIndex = null
      await this.waitTime(100);
      
      this.editItemIndex = videoProp.index
      
      this.editItem = this.finalEdit[this.editItemIndex].master
      this.videoURL = this.finalEdit[this.editItemIndex].full
      this.timeKey = new Date().getTime()
      
      // console.info(`ranges:`, this.finalEdit[this.editItemIndex].ranges)
      
      this.startVideo()
    },
    
    async CreateVideo(){
      //Build Post Data:
      if (this.finalEdit.length <=0) {
        this.$store.commit("dialog/setDialog", 
            { DialogType: 'alert', 
              title: 'Geen Videos geselecteerd', 
              message: 'Geen Videos geselecteerd', 
            }
        );
        return false
      }
      
      let VideoData = {
        bucket : this.bucket,
        prefix : this.S3Path,
        uploadVimeo: this.uploadVimeo,
        uploadAWS: this.uploadAWS,
        uploadM3u8: this.uploadM3u8,
        videoName: this.videoName,
        VideoRanges: [],
        msjwt: this.jwt,        
      }
      
      for(let video of this.finalEdit) {
        VideoData.VideoRanges.push({
          full: video.full,
          ranges: video.ranges,
        })
      }
      
      // console.info(`VideoData:`, VideoData)
      // console.info(`VideoData:`, JSON.stringify(VideoData))
      const rtn = await this.$store.dispatch('video_editor/setData', VideoData);
      // console.info(`rtn:--->`, rtn)
      if (rtn === false) {
        this.$store.commit("dialog/setDialog", 
            { DialogType: 'alert', 
              title: 'setVideo failed ?', 
              message: 'setVideo failed ?', 
            }
        );
        return false;
      }
      return true;
    },
    
    // async GotoPreview(){
    //   const code = await this.CreateVideo()
    //   console.info(`code:`, code)
    //   this.videoURL2 = `${process.env.VUE_APP_API_ROOT}/default/GetTempVideo.m3u8?videoHash=${code}`
    //   console.info(`this.videoURL2:`, this.videoURL2)
    //   this.startVideo2()
    // },
    async CreateVideoUrl(){
      if (this.uploadAWS === false && this.uploadVimeo === false && this.uploadM3u8 === false) {
        return this.$store.commit("dialog/setDialog", 
          { DialogType: 'alert', 
            title: 'Select at least one download option', 
            message: 'Select at least one download option (Video/Download)', 
          })
      }
      
      
      const rtn = await this.CreateVideo()
      // console.info(`rtn:`, rtn)
      if (rtn === false) return;
      
      
      this.$store.commit("dialog/setDialog", 
          { DialogType: 'alert', 
            title: 'The video is being created', 
            message: 'You will receive an email when it\'s done', 
          })
      this.videoURL = ''
      this.videoURL2 = ''
      this.S3Data = []
      this.editItem = null
      this.editItemIndex = null
      this.finalEdit = []
      this.resetPlayerState()
      this.isFinished = true
    },
    async DownloadVideo(){
      
    },
    
    
    
    getTimeStr,
    
    waitTime(WaitMilliSeconds = 0){
      return new Promise(function (resolve) {
        setTimeout(() => {
          resolve(true);
        }, WaitMilliSeconds);
      });
    },
    
  },
};
</script>

<style scoped>

  .MasterItemSelector{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* border: 1px solid #000; */
  }
  
  .MasterItemWrap{    
    max-width: 200px;
    position: relative;
    margin: 2px;
    margin-bottom: 10px;
    border: 1px solid rgba(211, 209, 209, 0.493);
  }
  .MasterItemWrap img{
    cursor: pointer;
  }
  
  .VideoDuration{
    float:right;
    position: absolute;
    top: 0px;
    right: 0px;
    line-height: 12px;;
    font-weight: bold;
    background-color: #FFF;
    padding: 2px;
  }
  
  .selectVideo{
    float:left;
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: 200;
    background-color: #FFF;
    padding: 2px;
    width: 30px;
    height: 30px;
  }
  
  .editVideo{
    float:left;
    position: absolute;
    bottom: 6px;
    right: 0px;
    font-weight: 200;
    padding: 2px;
    width: 50px;
    height: 30px;
  }
  
  .RangeTimes{
    float:left;
    width: 100%;
    height: auto;
  }
  
  .buttonExtra{
    margin: 15px;
  }
                        
</style>
<template>
  <div class="columns is-multiline is-centered">
    <b-field class="column is-12 videoRangeSelectorWrap">
      <b-slider :key="timeKey" v-model="numbers" :min="from" :max="till" indicator :tooltip="false" :custom-formatter="val => getTimeStr(val)"
        @dragend="dragEnded">
      </b-slider>
      
      <div class="InfoWrap">
        {{ getTimeStr(this.numbers[0]) }} - {{ getTimeStr(this.numbers[1]) }}
      </div>
      
      <div class="leftButtons">
        <span class="miniButton disable-select" @click="Changeumber(0,-60)">&lt;&lt;</span>
        <span class="miniButton disable-select" @click="Changeumber(0,-10)">&lt;</span>
        <span class="miniButton disable-select" @click="Changeumber(0,10)">&gt;</span>
        <span class="miniButton disable-select" @click="Changeumber(0,60)">&gt;&gt;</span>
      </div>
      
      <div class="rightButtons">
        <span class="miniButton disable-select" @click="Changeumber(1,-60)">&lt;&lt;</span>
        <span class="miniButton disable-select" @click="Changeumber(1,-10)">&lt;</span>
        <span class="miniButton disable-select" @click="Changeumber(1,10)">&gt;</span>
        <span class="miniButton disable-select" @click="Changeumber(1,60)">&gt;&gt;</span>
      </div>
    </b-field>
    
  </div>
</template>

<script>

import { getTimeStr } from '@/helpers/functions';

export default {
  name: "VideoRangeSelectrpr",
  props: {
    sections: {
      type: Array,
      required: true,
      default: new Array()
    },
    range: {
      type: Array,
      required: true,
      default: new Array()
    },
    from: {
      type: Number,
      required: true,
      default: 0
    },
    till: {
      type: Number,
      required: true,
      default: 100
    }
  },
  
  data() {
    return {
      numbers: [],
      oldValues: [],
      timeKey: new Date().getTime()
    }
  },    
  
  mounted(){
    
    /*console.info(`mounted: sections`, this.sections)
    console.info(`mounted: range`, this.range)
    console.info(`mounted: from`, this.from)
    console.info(`mounted: till`, this.till)*/
    
    this.numbers = this.range;
    this.oldValues = this.range;
  },
  
  methods: {
    Changeumber(index, changeVal){
      // const curIndex = this.findSectionIndex(this.numbers[index])
      // console.info(`curIndex:`, curIndex)
      
      // let NewIndex = Math.min(this.sections.length -1, Math.max(0, curIndex + changeVal))
      // let NewIndex = curIndex + changeVal
      // console.info(`NewIndex:`, NewIndex)
      
      this.numbers[index] = this.numbers[index] + changeVal
      
      this.numbers[0] = Math.max(this.from, this.numbers[0])
      this.numbers[1] = Math.min(this.till, this.numbers[1])
      
      this.numbers[1] = Math.max(this.numbers[0], this.numbers[1])
      
      // console.info(`this.numbers:`, this.numbers)
      this.dragEnded()
    },
    findSectionIndex(val){
      for(let key in this.sections) {
        if (this.sections[key] === val) {
          return parseInt(key)
        }
      }      
      return 0
    },    
    dragEnded(){
      
      if (this.numbers[0] !== this.oldValues[0]) {
        // this.numbers[0] = this.getClosestSectionStart(this.numbers[0])
        this.oldValues[0] = this.numbers[0]
        this.$emit('fromchanged', this.numbers[0])
      }
      if (this.numbers[1] !== this.oldValues[1]) {
        // this.numbers[1] = this.getClosestSectionEnd(this.numbers[1])
        this.oldValues[1] = this.numbers[1]
        this.$emit('tillchanged', this.numbers[1])
      }
      
      //force re-rendering
      this.timeKey = new Date().getTime()
    },
    getClosestSectionStart(val) {
      let lastval = this.sections[0];
      for(let key of this.sections) {
        // console.info(`getClosestSectionStart key:`, key)
        if (key <= val) {
          lastval = Math.max(lastval, key)
        }
      }
      return lastval
    },
    getClosestSectionEnd(val) {
      let lastval = this.sections[this.sections.length -1];
      for(let key of this.sections) {
        // console.info(`getClosestSectionEnd key:`, key)
        if (key >= val) {
          lastval = Math.min(lastval, key)
        }
      }
      return lastval
    },
    setRange(newRange){
      this.numbers = newRange;
    },
    getTimeStr,
  },
  
    
};
</script>

<style scoped>
  .videoRangeSelectorWrap{
    position: relative;
    display: float !important;
    padding-top: 15px !important;
    padding-bottom: 45px;
    width: 96% !important;
  }
  
  .leftButtons{
    position: absolute;
    left: 5px;
    bottom: 15px;
    width: 150px;
    height: 30px;
    z-index: 10;
    /* background-color: red; */
  }
  .rightButtons{
    position: absolute;
    right: 5px;
    bottom: 15px;
    width: 145px;
    height: 30px;
    z-index: 10;
    /* background-color: red; */
  }
  
  .InfoWrap{
    position: absolute;
    right: 5px;
    bottom: 0px;
    width: 100%;
    height: 30px;
    text-align: center;
    z-index: 1;
    /* background-color: red; */
  }
  
  .miniButton{
    float:left;
    text-align: center;
    width: 30px; 
    height: 30px;
    border-radius: 15px;
    line-height: 25px;
    margin: 2px; 
    border: 1px solid #ff7e82;
    background-color: #ff7e82;
    color: #fff;
    cursor: pointer;
  }  
  .miniButton:hover {
    border: 1px solid #FFF;
  }
  .miniButton:active {
    background-color: #ff7e829f;
  }
  
  .disable-select {
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
  }
  
   
</style>